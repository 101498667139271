import React from "react";
import "./Home.css";
import ImageSlider from "../components/ImageSlider";
import selectedDishes from "../data/selectedDish"; // Import the selected dishes data
import Link from "../components/Link";

const Home = () => {
  const images = [
    {
      imgURL: process.env.PUBLIC_URL + "/蘇澳港直送深海魚.jpg",
      imgAlt: "img-1",
    },
    {
      imgURL: process.env.PUBLIC_URL + "/10人桌菜-2.jpg",
      imgAlt: "img-2",
    },
    {
      imgURL: process.env.PUBLIC_URL + "/招牌菜.jpg",
      imgAlt: "img-3",
    },
  ];

  return (
    <>
      <div className="home-container">
        <div className="menu-image-container">
          <img src={process.env.PUBLIC_URL + "/2025年菜.jpg"} alt="年菜菜單" />
        </div>
        <div className="home-image-container">
          <ImageSlider>
            {images.map((image, index) => (
              <img key={index} src={image.imgURL} alt={image.imgAlt} />
            ))}
          </ImageSlider>
        </div>

        {/* <div className="menu-introduction">
          <div className="menu-feature">
            <h2>菜單介紹</h2>
            <p className="menu-feature-text">
              #江浙菜 #海鮮料理 #上海菜 #包廂桌菜 #現流深海魚 #個人套餐
              #廚房衛生
            </p>
          </div>
          <div className="menu-options">
            <div className="menu-option">
              <img src={process.env.PUBLIC_URL + "/f1.jpg"} alt="定食套餐" />
              <p>定食套餐</p>
            </div>
            <div className="menu-option">
              <img src={process.env.PUBLIC_URL + "/f1.jpg"} alt="單點菜單" />
              <p>單點菜單</p>
            </div>
            <div className="menu-option">
              <img src={process.env.PUBLIC_URL + "/f1.jpg"} alt="桌菜菜單" />
              <p>桌菜菜單</p>
            </div>
          </div>
        </div> */}

        <div className="info-section">
          <div className="info-block private-room-text">
            <h2>包廂介紹</h2>
            <div className="room-description-container">
              <p>
                每間包廂經過精心設計，完美融合現代與傳統元素，讓您在享受美食的同時，感受獨特的文化氛圍。
                我們的包廂適合商務會議、家庭聚餐及慶祝活動，並提供以下專業設備及功能：
              </p>
              <ul className="feature-list">
                <li>
                  <strong>高畫質投影設備：</strong>
                  支援各類會議簡報及聚會投影需求，讓您的活動更具專業感。
                </li>
                <li>
                  <strong>麥克風及音響系統：</strong>
                  提供清晰的聲音效果，無論是會議講解或活動互動都能輕鬆應對。
                </li>
                <li>
                  <strong>舒適的私密空間：</strong>
                  確保活動不被打擾，讓每位賓客都能自在享受。
                </li>
              </ul>
              <p>
                無論是商務洽談還是溫馨聚會，我們都致力於為您打造難忘的用餐體驗！
              </p>
            </div>

            <Link href="/room-menu" className="button-link">
              查看包廂菜單
            </Link>
          </div>
          <div className="info-block private-room-image">
            <img
              src={process.env.PUBLIC_URL + "/room1.jpg"}
              alt="晶櫻燴包廂照片"
            />
          </div>
        </div>

        <div className="selected-dishes">
          <h2>精選佳餚</h2>
          <div className="food-grid">
            {selectedDishes.map((dish, index) => (
              <div key={index} className="food-box">
                <img src={dish.src} alt={`food-${index + 1}`} />
                <div className="overlay">
                  <h3>{dish.name}</h3>
                  <p>{dish.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div class="blog-section">
          <h2 class="blog-title">部落客推薦文章</h2>
          <ul class="blog-list">
            <li>
              <a href="https://tenjo.tw/erito/" target="_blank">
                忠孝復興站美食：晶櫻燴江浙海鮮料理，台北東區聚會餐廳推薦！
              </a>
              <p>
                位於台北東區的晶櫻燴Erito江浙海鮮料理，提供正宗的江浙菜，包括上海、杭州、揚州和蘇州菜。餐廳環境寬敞舒適，設有三間可合併的包廂，適合家庭聚餐、慶生壽宴、春酒尾牙等活動。推薦菜色有上海肴豬腳、上海蟹粉菜飯、碧綠河蝦仁等，皆以新鮮食材製作，風味獨特。
              </p>
            </li>
            <li>
              <a href="https://mecotravel.tw/taipei-erito/" target="_blank">
                台北東區美食：晶櫻燴Erito江浙海鮮料理，桌菜尾牙聚餐推薦
              </a>
              <p>
                晶櫻燴Erito江浙海鮮料理坐落於台北東區，是品嚐江浙佳餚的理想場所。主廚以經典手法結合創意，將上海、杭州等地的特色料理呈現在餐桌上。餐廳設有雅緻包廂和舒適用餐空間，非常適合舉辦家庭聚餐、商務宴請及各類節慶聚會。以新鮮食材為基礎，招牌菜色如蟹黃豆腐與蔥香清蒸海上鮮，深受食客喜愛，讓每位來賓都能感受到地道與創新的完美融合。
              </p>
            </li>
            <li>
              <a href="https://blaketravel.tw/blog/post/erito" target="_blank">
                台北東區江浙料理：晶櫻燴Erito江浙海鮮料理，必點上海肴豬腳與菜飯
              </a>
              <p>
                晶櫻燴Erito江浙海鮮料理位於台北東區，主打正宗的江浙佳餚，融合上海、杭州等地的經典風味。餐廳以溫馨且高雅的氛圍迎接每位食客，寬敞包廂與精緻擺設特別適合舉辦家庭聚會或商務宴請。推薦菜色如上海肴豬腳與上海蟹粉菜飯，不僅口味道地，更展現了烹飪的精緻與細膩，讓每一口都回味無窮。
              </p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Home;
